import React from 'react'
import { Link, PageProps } from 'gatsby'
import * as st from '~/assets/styl/404.module.styl'
import bauerLogo from '~/assets/svg/svg-logo-red.svg'

const Attendance = ({ location }: PageProps) => (
  <div className={st.content1364}>
    <Link to="/">
      <img src={bauerLogo} alt="Logo da Bauer Postos" />
    </Link>
    <section className={st.notFound}>
      <h2>
        Mensagem enviada <br />
        com sucesso!
      </h2>
      <p>
        Sua mensagem foi enviada para nossa equipe de relacionamento. <br />
        Em breve entraremos em contato para melhor atende-lo.
      </p>
      <div>
        <Link to="/">Ir para Home</Link>
      </div>
    </section>
  </div>
)

export default Attendance
